import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Layout } from "../../components";
import Block from "../../components/cta.js";
import VizPage from "../../components/vizPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <VizPage heading="Eviction and Eviction Filing Rates by Tract" mdxType="VizPage">
        <iframe src="https://public.tableau.com/views/CityofMilwaukeeEvictionRate/EvictionJudgementRate?:showVizHome=no&:embed=true&:display_count=yes" allowFullScreen="true" width="815" height="835"></iframe>
        <br></br>
        <h2>{`About This Map`}</h2>
        <br></br>
        <dt> <strong> Eviction filing rate </strong> represents the number of evictions filed per 100 rental households per year. <strong> Eviction rate </strong> represents the number of evictions issued per 100 rental households per year. Eviction data are taken from the WCCA Rest Interface. Census estimates are taken from the ACS 5-Year Estimates for each year. 2018 uses the most recent data available: 2013-2017 ACS 5-Year Estimates. </dt>
        <br></br>
        <dl>
          <dt> Data are classified using the Fisher Jenks optimal classification method available in Pysal.</dt>
        </dl>
      </VizPage>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      